import React from 'react'
import { ArrowLeft, ExternalLink, Image } from 'lucide-react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const projects = [
  {
    title: "HAPPYFARES SUPPORT",
    description: "Built a flight booking website for HappyFares, designed to assist users in booking flights seamlessly and providing customer support services.",
    link: "https://www.help.happyfares.in/",
  },
  {
    title: "Renttly",
    description: "Developed a car rental platform, Renttly, offering a streamlined booking experience for customers looking to rent vehicles.",
    link: "https://www.renttly.in/",
  },
  {
    title: "Mytaxsupport",
    description: "Created a comprehensive financial services website to help users manage their taxes efficiently.",
    link: "https://www.mytaxsupport.in/",
  },
  {
    title: "Social Media Management",
    description: "Manage multiple social media profiles, focusing on branding, digital marketing strategies, and graphics design to increase engagement and visibility.",
    link: "#",
  },
  {
    title: "Graphics Designing",
    description: "Skilled in graphics designing, creating compelling visuals for digital and print media to enhance branding and marketing efforts.",
    link: "#",
  },
]

function ProjectsPage() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-white text-gray-900 font-sans"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <header className="flex justify-between items-center mb-12">
          <Link to="/" className="flex items-center text-xl font-semibold text-gray-900 hover:text-gray-600 transition-colors">
            <ArrowLeft className="mr-2" size={24} />
            Back to Portfolio
          </Link>
          <h1 className="text-3xl font-bold">My Projects</h1>
        </header>

        <main>
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.2
                }
              }
            }}
            initial="hidden"
            animate="show"
          >
            {projects.map((project, index) => (
              <motion.div 
                key={index} 
                className="bg-gray-100 rounded-[15px] overflow-hidden"
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  show: { opacity: 1, y: 0 }
                }}
              >
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <h2 className="text-xl font-semibold">{project.title}</h2>
                    <Image size={24} className="text-gray-400" />
                  </div>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 flex items-center">
                    Visit <ExternalLink size={16} className="ml-1" />
                  </a>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </main>
      </div>
    </motion.div>
  )
}

export default ProjectsPage