import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Twitter, Linkedin, Mail, ExternalLink, ChevronRight, Instagram, Facebook, Plane, CreditCard, Banknote } from 'lucide-react';
import { motion } from 'framer-motion';
import './App.css';

function HomePage() {
  const [text, setText] = useState('');
  const fullText = "I'm Masoom Das.";

  useEffect(() => {
    let i = 0;
    const typingEffect = setInterval(() => {
      if (i < fullText.length) {
        setText(prevText => prevText + fullText.charAt(i));
        i++;
      } else {
        clearInterval(typingEffect);
      }
    }, 100);

    return () => clearInterval(typingEffect);
  }, []);

  const skills = [
    "Flutter", "HTML", "Wix", "Chatbot", "AWS", "Cloudflare", "UI/UX", "Figma",
    "Digital Marketing", "Graphic Designing", "Adobe Illustrator", "Firebase", "Automation"
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-white text-gray-900 font-sans flex items-center justify-center"
    >
      <div className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-12 flex flex-col h-full">
        <header className="flex flex-col sm:flex-row justify-between items-center mb-8">
          <div className="flex items-center space-x-4 mb-4 sm:mb-0">
            <img 
              src="https://media.licdn.com/dms/image/v2/C4D03AQGBc2q3Xsomlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650646895796?e=2147483647&v=beta&t=Q40I9MUf2H7aIiuhkpjWmtdMU9E6Dd-T5SsoTE9U890" 
              alt="Masoom Das" 
              className="w-20 h-20 rounded-full"
            />
            <div>
              <h1 className="text-4xl font-bold">{text}<span className="animate-blink">|</span></h1>
              <p className="text-xl text-gray-600">Developer & Entrepreneur</p>
            </div>
          </div>
          <a href="mailto:masoomdax@gmail.com" className="bg-black text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition-colors inline-flex items-center">
            <Mail className="mr-2" size={20} />
            Contact Me
          </a>
        </header>

        <main className="flex-grow flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-12">
          <section className="flex-1">
            <h2 className="text-3xl font-bold mb-6">About Me</h2>
            <p className="text-lg text-gray-600 mb-6">
              I'm a passionate developer and entrepreneur with a keen eye for innovation. I specialize in creating cutting-edge solutions that push the boundaries of technology.
            </p>
            <p className="text-lg text-gray-600 mb-8">
              As the founder of Hober, I'm dedicated to revolutionizing the way we interact with technology, creating seamless, user-centric experiences that make a real difference in people's lives.
            </p>
            <div className="flex space-x-6 mb-8">
              <a href="https://x.com/MasoomDax" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black">
                <Twitter size={24} />
              </a>
              <a href="https://www.linkedin.com/in/masoomdax/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black">
                <Linkedin size={24} />
              </a>
              <a href="https://www.instagram.com/masoomdax/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black">
                <Instagram size={24} />
              </a>
              <a href="https://www.facebook.com/masoomdax/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black">
                <Facebook size={24} />
              </a>
              <a href="https://g.co/kgs/7JvPazy" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M12 8l4 4-4 4M8 12h8"></path>
                </svg>
              </a>
            </div>
            <div className="flex flex-wrap gap-2">
              {skills.map((skill, index) => (
                <span key={index} className="bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm">
                  {skill}
                </span>
              ))}
            </div>
            <footer className="mt-8 text-center text-gray-600 text-lg">
          <p>&copy; 2023 Masoom Das. All rights reserved.</p>
        </footer>
          </section>

          <section className="flex-1">
            <div className="mb-12">
              <h2 className="text-3xl font-bold mb-6">Company</h2>
              <div className="bg-gray-100 p-6 rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-2xl font-semibold">Hober</h3>
                  <a href="https://hober.in" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black">
                    <ExternalLink size={20} />
                  </a>
                </div>
                <p className="text-lg text-gray-600 mb-6">Innovative travel solutions for the modern world</p>
                <div className="flex flex-col space-y-4">
                  <div className="flex items-center">
                    <Plane className="mr-3" size={20} />
                    <span className="text-lg text-gray-600">Specializing in cheap flight bookings</span>
                  </div>
                  <div className="flex items-center">
                    <CreditCard className="mr-3" size={20} />
                    <span className="text-lg text-gray-600">Visa application services</span>
                  </div>
                  <div className="flex items-center">
                    <Banknote className="mr-3" size={20} />
                    <span className="text-lg text-gray-600">Currency exchange solutions</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-3xl font-bold mb-6">Projects</h2>
              <Link to="/projects" className="bg-gray-100 text-black px-8 py-4 rounded-full text-lg font-semibold hover:bg-gray-200 transition-colors inline-flex items-center">
                View All Projects
                <ChevronRight size={20} className="ml-2" />
              </Link>
            </div>
          </section>
        </main>
      </div>
    </motion.div>
  );
}

export default HomePage;